import bynderDATLoader from './bynderDATLoader'


export default function getSrcSet({
  id,
  name,
  aspectRatio,
  quality,
  maxWidth = 2000,
  maxHeight = 2000,
}) {
  return [256, 384, 512, 640, 750, 828, 1080, 1200, 1440, 1920, 2400]
    .map(
      (width) =>
        bynderDATLoader({
          src: `https://img.boalingua.ch/transform/${id}/${name}`,
          width,
          quality,
          aspectRatio,
          maxWidth,
          maxHeight,
        }) + ` ${width}w`
    )
    .join(', ')
}
