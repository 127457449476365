import PropTypes from 'prop-types'
import cx from 'classnames'

import Typography from '../Typography'
import CTALink from '../CTALink'
import classNames from './styles.module.scss'
import { ImageType } from '../blocks/types'
import { BynderPicture, GradientBlock } from 'common/widgets'


ImageBlock.propTypes = {
  image: ImageType,
  heading: PropTypes.string,
  hover_text: PropTypes.string,
  cta: PropTypes.string,
  cta_link: PropTypes.string,
  className: PropTypes.string,
  cta_link_type: PropTypes.string,
  children: PropTypes.node,
}

ImageBlock.defaultProps = {
  image: undefined,
  heading: undefined,
  hover_text: undefined,
  cta: undefined,
  cta_link: undefined,
  className: undefined,
  children: undefined,
  cta_link_type: undefined,
}

export default function ImageBlock({ image, heading, hover_text: hoverText, cta, cta_link: ctaLink, className, cta_link_type: ctaLinkType, children }) {
  return (
    <div className={cx(classNames.container, className)}>
      {image.bynder && (
        <BynderPicture
          image={image}
          priority
          desktopImage={{
            maxWidth: 444,
            maxHeight: 500,
            sizes: '(max-width: 1024px) 48vw, (max-width: 1440px) 30vw, 444px',
          }}
          mobileImage={{
            maxWidth: 540,
            maxHeight: 450,
            sizes: '90vw',
          }}
        />
      )}
      <GradientBlock />
      <div className={classNames.info}>
        <div>
          <div className={classNames.heading}>
            <Typography variant="h3" color="white" className={classNames.title}>
              {heading}
            </Typography>
          </div>
          <Typography variant="content" color="white" className={classNames.text}>{hoverText}</Typography>
        </div>
        <CTALink
          cta={cta}
          ctaURL={ctaLink}
          icon="chevron-right"
          className={classNames.cta}
          type={ctaLinkType}
        />
      </div>
      {children}
    </div>
  )
}
