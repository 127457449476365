import { useMemo, useState } from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import classNames from './styles.module.scss'
import imgPlaceholder from '@img/noimage.svg'
import BynderPicture from '../BynderPicture/BynderPicture'
import { useTranslations } from 'common/language'


CardSlider.propTypes = {
  images: PropTypes.array.isRequired,
  className: PropTypes.string,
  priority: PropTypes.bool,
}

CardSlider.defaultProps = {
  className: undefined,
  priority: false,
}

export default function CardSlider({ images, className, priority }) {
  const [order, changeOrder] = useState(0)
  const { languageCode } = useTranslations()
  const normalizedImages = useMemo(() => images.map(i => ({
    bynder: i.bynder,
    data: {
      alttext: get(i, 'data.alttext', get(i, `data.alttext_${languageCode}`)),
      name: get(i, 'data.name', get(i, `data.name_${languageCode}`)),
    },
  })), [images])
  const settings = useMemo(_ => ({
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    afterChange: changeOrder,
  }), [])
  if(isEmpty(images)) {
    return (
      <div className={cx(classNames.cardImg, classNames.placeholder)}>
        <img src={imgPlaceholder} className={classNames.imgPlaceholder} alt="noImage" />
      </div>
    )
  }
  const slides = useMemo(_ =>
    normalizedImages.map((i, index) => (
      <div key={get(i, 'bynder')} className={cx(classNames.cardImgBlock, 'card-slide')}>
        <BynderPicture
          image={i}
          priority={priority && index === 0}
          mobileImage={{
            maxWidth: 700,
            maxHeight: 350,
            sizes: '100vw',
          }}
          desktopImage={{
            maxWidth: 800,
            maxHeight: 600,
            sizes: '(max-width: 1024px) 100vw, 400px',
          }}
        />
      </div>
    )
    ), [images])
  return (
    <>
      <div className={classNames.slideWrapper}>
        <div className={classNames.sliderOrdering}>
          {`${order + 1} / ${images.length}`}
        </div>
        <Slider {...settings} className={cx(classNames.cardSlider, className)}>
          {slides}
        </Slider>
      </div>
      <div className={classNames.printImage}>
        <BynderPicture
          image={images[0]}
          priority={priority}
          mobileImage={{
            maxWidth: 700,
            maxHeight: 350,
            sizes: '100vw',
          }}
          desktopImage={{
            maxWidth: 800,
            maxHeight: 600,
            sizes: '(max-width: 1024px) 100vw, 271px',
          }}
        />
      </div>
    </>
  )
}
