import get from 'lodash/get'
import PropTypes from 'prop-types'

import classNames from './styles.module.scss'
import getSrcSet from 'common/utils/getSrcSet'
import { ImageType } from '../blocks/types'
import bynderDATLoader from 'common/utils/bynderDATLoader'
import cx from 'classnames'

/*
Workaround for empty srcset;
To prevent unneseccary requests, we use a 1px transparent gif when we don't need to load an image
For example when mobileImage is hidden (see Accordion component)
*/
const emptySrc = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='

BynderPicture.defaultProps = {
  quality: undefined,
  mobileImage: undefined,
  className: undefined,
  priority: false,
  onLoad: undefined,
}

BynderPicture.propTypes = {
  image: ImageType.isRequired,
  priority: PropTypes.bool,
  quality: PropTypes.number,
  className: PropTypes.string,
  onLoad: PropTypes.func,
  mobileImage: PropTypes.shape({
    maxWidth: PropTypes.number.isRequired,
    maxHeight: PropTypes.number.isRequired,
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
  }),
  desktopImage: PropTypes.shape({
    maxWidth: PropTypes.number.isRequired,
    maxHeight: PropTypes.number.isRequired,
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
  }).isRequired,
}


export default function BynderPicture({ image, priority, mobileImage, desktopImage, quality, className, onLoad }) {
  const fallbackSrc = bynderDATLoader({
    src: `https://img.boalingua.ch/transform/${image?.bynder}/${image?.data?.name}`,
    width: desktopImage.maxWidth,
    maxWidth: desktopImage.maxWidth,
    maxHeight: desktopImage.maxHeight,
    quality,
  })
  return (
    <span className={cx(classNames.picture, className)}>
      <picture>
        <source
          media="(max-width: 575px)"
          srcSet={mobileImage ? getSrcSet({
            id: image?.bynder,
            name: image?.data?.name,
            aspectRatio: mobileImage.aspectRatio,
            maxWidth: mobileImage.maxWidth,
            maxHeight: mobileImage.maxHeight,
            quality,
          }) : emptySrc}
          sizes={mobileImage?.sizes}
        />
        <source
          media="(min-width: 576px)"
          srcSet={getSrcSet({
            id: image?.bynder,
            name: image?.data?.name,
            aspectRatio: desktopImage.aspectRatio,
            maxWidth: desktopImage.maxWidth,
            maxHeight: desktopImage.maxHeight,
            quality,
          })}
          sizes={desktopImage.sizes}
        />
        <img
          src={fallbackSrc}
          onLoad={onLoad}
          alt={get(image, 'data.alttext')}
          title={get(image, 'data.name')}
          decoding="async"
          loading={priority ? 'eager' : 'lazy'}
        />
      </picture>
    </span>
  )
}
