import emptyPlaceholder from 'img/empty-placeholder.svg'


export default function bynderDATLoader({ src, width, quality, aspectRatio, maxWidth = 2000, maxHeight = 2000 }) {
  if(!src) {
    return emptyPlaceholder
  }
  const ratio = aspectRatio || maxWidth / maxHeight
  let newWidth
  let newHeight
  newWidth = Math.min(width, maxWidth)
  newHeight = newWidth / ratio
  if(newHeight > maxHeight) {
    newHeight = maxHeight
    newWidth = maxHeight * ratio
  }
  return src + `?io=transform:fill,width:${newWidth.toFixed()}${ratio ? ',height:' + newHeight.toFixed() : ''}${quality ? `&quality=${quality}` : ''}`
}
