import PropTypes from 'prop-types'


const ImageType = PropTypes.shape({
  data: PropTypes.shape({
    name: PropTypes.string,
    alttext: PropTypes.string,
    caption: PropTypes.string,
  }),
  bynder: PropTypes.string,
})

export default ImageType
